_cruxUtils._cruxLocale = {
"crux.no.bc.message":"Ingen oversigt er tilg\u00e6ngelig. Klik p\u00e5 posten for at se flere oplysninger",
"crm.appointments.status.markcompleted1": "Marker som afsluttet",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">Alle\u00a0</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}\u00a0</span><span class=\"dIB vaM\"> {1} i denne visning er blevet valgt.</span>",
"crm.module.selectall": "V\u00e6lg Alle {0} i denne visning",
"records": "poster",
"crm.record.not.accessible": "Post(er) ikke tilg\u00e6ngelig(e)",
"crm.view.calls.recording.err.msg": "Stemmeoptagelse har ikke en URL til afspilning af lyd.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} valgt.</span>",
"totalrecords": "Poster i alt",
"crm.listview.options.text.clip": "Klip tekst",
"crm.listview.options.text.wrap": "Ombryd tekst",
"crm.listview.options.reset.width": "Nulstil kolonnest\u00f8rrelse",
"crm.listview.options.manage": "Administrer kolonner",
"crm.listview.customview.locked": "Kolonner kan ikke tilpasses, fordi denne visning er l\u00e5st",
"crm.api.apidashboard.SortBy": "Sorter efter",
"crm.privacy.listview.consent.locked": "Posten er blevet l\u00e5st for behandling",
"crm.label.reschedule.call": "Ryk {0}",
"crm.button.edit": "Rediger",
"Delete": "Slet",
"crm.appointments.status.markcancel": "Annuller {0}",
"crm.appointments.status.markreschedule": "Ryk {0}",
"crm.label.cancel.call": "Annuller {0}",
"crm.label.mark.completed": "Marker som afsluttet",
"crm.listview.customview.locked": "Kolonner kan ikke tilpasses, fordi denne visning er l\u00e5st",
"pf.deleted.state": "Slettet tilstand",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "Synkroniseret med {0}",
"crm.schedules.prefix.in": "I",
"crm.record.record.locked.other": "Posten er l\u00e5st for andre profilbrugere.",
"crm.record.lock.record.locked": "Posten er l\u00e5st.",
"crm.approvalProcess.label.waitingForApproval": "Venter p\u00e5 godkendelse",
"crm.reviewprocess.record.review.pending": "Afventer genneml\u00e6sning",
"crm.approvalProcess.label.waitingForFindAndMerge": "Fletning af dubletter er i gang.",
"crm.privacy.listview.consent.locked": "Posten er blevet l\u00e5st for behandling",
"crm.zia.vision.processing": "Zia validerer billedet/billederne.",
"crm.zia.vision.rejected.msg": "Posten er afvist p\u00e5 grund af fejl i validering af billede.",
"crm.zia.vision.record.failure.msg": "Posten afventer godkendelse p\u00e5 grund af fejl i validering af billede.",
"crm.alert.max.cvcols.reached": "Du kan kun v\u00e6lge op til maks. {0} kolonner.",
"crm.label.small.tags": "Tags",
"crm.label.edit.module": "Rediger {0}",
"crm.social.integ.no.result": "Ingen resultater fundet",
"custommodule.crmfield.fl.name": "{0} Navn",
"Appointment": "Aftale",
"crm.button.create.call": "Opret opkald",
"crm.module.create": "Opret {0}",
"crm.customview.activty.badge": "Aktivitetsbadge",
"crm.button.callnow": "Ring nu",
"crm.manualcalllist.complete.call": "Log et opkald",
"crm.label.schedule.call": "Planl\u00e6g et opkald",
"crm.filter.label.replied":"besvaret",
"bytes": "bytes",
"crm.field.label.user.lookup": "Bruger",
"crm.translation.refresh.cases": "Den handling, du pr\u00f8ver at udf\u00f8re, kunne ikke afsluttes. Opdater siden og pr\u00f8v igen.",
"crm.project.sync.notes.info": "Der vil kun vises opgavernes kommentarer for de tilknyttede projekter",
"crux.note.content.limit.warning.msg":"Kun tilladt at gemme {0} tegn i noteindhold",
"crux.note.formatting.options":"Formateringsindstillinger",
"crm.label.required": "P\u00e5kr\u00e6vet",
"crux.condition.not.belongs.to":"tilh\u00f8rer ikke {0}",
"crm.project.sync.notes.info": "Der vil kun vises opgavernes kommentarer for de tilknyttede projekter",
"crm.sf.permission.no.edit": "Du har ikke tilladelse til at opdatere poster.",
"crm.sf.permission.no.create": "Du har ikke tilladelse til at tilf\u00f8je poster.",
"crm.sf.permission.no.delete": "Du har ikke tilladelse til at slette poster.",
"crux.note.this.note":"denne bem\u00e6rkning",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "Indlogget brugerrolle",
"Unit Price": "Enhedspris",
"Qty in Stock": "Antal p\u00e5 lager",
"crm.label.Product Code": "Produktkode",
"TotalAfterDiscount": "Samlet efter rabat",
"crm.lookupfilter.entity.errormsg": "Den valgte {0} opfylder ikke opslagskriterierne.",
"crm.iamexception.maxlen": "Du kan ikke indtaste mere end {0} tegn for {1}",
"crm.record.locking.permission.denied": "Denne handling kan ikke udf\u00f8res, fordi posten er l\u00e5st.",
"List Price": "Listepris",
"crm.label.no.more.records": "Ikke flere poster",
"crm.workflow.rule.created.dateAndTime":"{0} den {1} kl. {2}",
"crux.new.note.msg":"\u201dHvad handler denne bem\u00e6rkning om?\u201d",
"crm.image.uploaded":"Billede uploadet",
"crm.imageupload.drag.drop.here":"Tr\u00e6k og slip billederne her",
"crm.fileupload.drag.drop.here":"Tr\u00e6k og slip filerne her",
"crm.fileupload.wait.msg":"Vent venligst, indtil de valgte filer er uploadet",
"crm.fileupload.attach.fail":"Vedh\u00e6ftning mislykkedes",
"crm.fileupload.support.format":"Format af supportfil (jpeg, jpg, png, pdf) for Zia\u2019s tr\u00e6ning",
"custmr.prtl.user.role": "Portalbruger",
"crm.subform.empty.alert.header":"Tom r\u00e6kke kan ikke gemmes",
"crm.crud.lookup.module.inaccessible": "Du har ikke tilladelse til at have adgang til dette opslagsmodul",
"crm.crud.lookup.inaccessible.record": "Poster findes ikke eller er utilg\u00e6ngelige for dig",
"crm.custombutton.valid.weburl.check": "Indtast nogen v\u00e6rdi.",
"crm.label.subform.goto.top": "G\u00e5 til toppen af listen",
"crm.label.subform.row.show.all": "Vis alle ({0})",
"cpq.pr.nodiscount": "Ingen rabatter er tilknyttet",
"crm.inventory.lineitem.no.pricebook.new": "Ingen {0} er tilknyttet.",
"crm.label.subform.allowedlimit": "Gr\u00e6nsen p\u00e5 {0} r\u00e6kker n\u00e5et.",
"crm.reports.integ.okay": "OK",
"crm.crud.subform.deleted.record": "R\u00e6kker i opdateret underformular er allerede slettet. Opdater siden, og pr\u00f8v igen.",
"crux.criteria.fieldlabel.valid.check":"Skriv en gyldig feltetiket",
"crm.subform.delete.confirm.header":"Slet underformular række",
"crm.subform.delete.confirm.msg": "Er du sikker på",
"crm.subform.delete.tooltip.msg" :"Rækken kan ikke slettes. Mindst en rækkepost er påkrævet til {0}.",
	"crm.condition.belongs.to":"tilh\u00f8rer {0}",//no i18n
"crm.condition.not.belongs.to":"tilh\u00f8rer ikke {0}",//no i18n
"crm.orchestration.error.selectvalue": "V\u00e6lg en v\u00e6rdi",
	"crm.attach.upload.image":"Vedh\u00e6ft billeder",//no i18n
"crm.general.select.photo":"V\u00e6lg billede",//no i18n
"crm.imageupload.wait.msg":"Vent vent venligst, indtil de valgte billeder overf\u00f8res.",//no i18n
"crm.image.filepicker.desc.draganddrop":"Tr\u00e6k og slip billeder.",//no i18n
"crm.image.filepicker.desc.browse":"Klik for at gennemse billeder...",//no i18n
"crm.attach.upload.userinfo":"Samlet st\u00f8rrelse er begr\u00e6nset til <span class",//no i18n
"crm.image.supported.formats":"Underst\u00f8ttede formater: JPEG, PNG, GIF og BMP.",//no i18n
"crm.imageupload.allowed.field.length":"Du kan maksimalt uploade {0} billede(r).",//no i18n
"crm.general.crop.and.set":"Besk\u00e6r og s\u00e6t",//no i18n
"crm.attach.option.label":"Vedh\u00e6ft",//no i18n
"crm.image.crop.and.rotate":"Besk\u00e6r og rot\u00e9r",//no i18n
"crm.image.resolution":"L\u00f8sning",//no i18n
"crm.attachment.size":"St\u00f8rrelse",//no i18n
"crm.label.close":"Luk",//no i18n
"crm.label.previous":"Forrige",//no i18n
"crm.label.next":"N\u00e6ste",//no i18n
"crm.subform.record.create.maxrow.limit": "Maksimalt {0} poster tilladt i {1}",
"crm.label.subform.addrows": "Tilf\u00f8j r\u00e6kke",
"crm.tax.association.check": "Ingen moms er forbundet med dette produkt.",
	"crm.gallery.inventory.template.discount": "Rabat",
	"crm.inventory.discount.scheme.range": "Interval",
	"Description": "Beskrivelse",
	"crm.inventory.lineitem.no.pricebook": "Ingen prisb\u00f8ger er tilknyttet.",
	"crm.recordImage.previewimage":"Vis billede",//no i18n
"crm.FileuploadField.addNewImage":"Upload billede",//no i18n
"crm.fileuploader.removefile":"Fjern",//no i18n
	"voc.gc.configure":"Konfigurer",//no i18n
"Edit":"Rediger",//no i18n
	"crm.view.attachment.download":"Download",//no i18n
"crm.label.view":"Se",//no i18n
	"crm.criteria.secondaryfield.valid.check" :"Angiv matchende v\u00e6rdi for {0}",
	"crm.label.field":"Felt",//no i18n
"crm.label.value":"V\u00e6rdi",//no i18n
	"crm.button.clone":"Klon",//no i18n
	"sentiment.criteria.wrongcriteria":"Kriteriev\u00e6rdi m\u00e5 ikke v\u00e6re mere end {0}",//no i18n
"crm.mb.field.common.splc":"Specialtegn er ikke tilladt. Indtast en gyldig v\u00e6rdi.",//no i18n
	"crm.label.field.plural": "felter",//no i18n
	"crm.label.in.minutes":"{0} (i minutter)",//no i18n
	"crm.security.roles.list":"Rolleliste",//no i18n
"crm.security.roles.lookup.info":"V\u00e6lg en rolle fra listen.",//no i18n
"crm.territory.addterritory":"Tilf\u00f8j omr\u00e5de",//no i18n
"crm.title.edit.territory":"Rediger omr\u00e5de",//no i18n
"crm.territory.title.assign.territories":"Tildel omr\u00e5der",//no i18n
	"crm.label.context.help":"Hj\u00e6lp",//no i18n
	"crm.label.from":"Fra",//no i18n
"crm.label.to":"Til",//no i18n
	"workflow.option.webhookFailure.fromDate":"Fra-dato",//no i18n
"workflow.option.webhookFailure.toDate":"Til-dato",//no i18n
"crm.custom.field.less.than.equalto":"{0} skal v\u00e6re mindre end eller lig med {1}.",//no i18n
	"crm.wf.usage.date.criteria.error.msg":"Fra-dato skal ligge f\u00f8r Til-dato.",//no i18n
	"crm.template.listview.search.no.results":"Ingen resultater fundet",//No I18n
	"crm.label.tag.new":"Nyt tag",//No I18n
	"crm.label.enter.tag":"Indtast tags",//No I18n
	"crux.comboBox.max.limit":"Du kan ikke v\u00e6lge mere end {0} {1}.",//NO I18n
	"Administrator":"Administrator",//No I18n
	"Standard":"Standard",//No I18n
	"crm.button.add":"Tilf\u00f8j",//NO I18n
	"crm.label.users":"Brugere", //NO I18n
  "crm.workflow.alert.roles":"Roller", //NO I18n
  "crm.security.groups":"Grupper", //NO I18n
	"crm.label.available" : "Tilg\u00e6ngelig", //NO I18n
	"crm.label.assign.manually" : "Tildel", //NO I18n
	"crm.globalsearch.option.all": "Alle", //NO I18n
	"webform.status.Active":"Aktiv", //NO I18n
	"Inactive":"Inaktiv", //NO I18n
  "Confirmed":"Bekr\u00e6ftet", //NO I18n
  "DeletedUser":"Slettet", //NO I18n
  "crm.user.component.unconfirmed":"Ubekr\u00e6ftet",//no i18n
  "crm.feed.group.admin":"Administrator", //NO I18n
  "crm.ln.lable.current":"Aktuel", //NO I18n
	"crm.label.selected": "Udvalgt",//NO I18n
  "crm.auditlog.user": "Bruger", //NO I18n
  "cob.role": "Rolle", //NO I18n
  "zoho.email": "E-mail", //NO I18n
  "Profile": "Profil", //NO I18n
	"crm.security.group.users.empty": "Der blev ikke fundet brugere.", //NO I18n
	"crm.label.picklist.none": "Ingen", //NO I18n
	"crm.label.notSelected" : "Ikke valgt",//NO I18n
	"AM" : "Efter midnat",//NO I18n
	"Call" : "Opkald",//NO I18n
	"crm.phoneNo.Link.Title" : "Ring ved hj\u00e6lp af Skype",//NO I18n
	"crm.button.cancel" : "Annuller",//NO I18n
	"crm.button.save" : "Gem",//NO I18n
	"crm.no.data.found" : "Der blev ikke fundet nogen data.",//NO I18n
	"crm.usrpop.non.selected" : "Valgte brugere",//NO I18n
	"crm.zti.label.user": "Brugernavn", //NO I18n
	"crm.label.no.options.found" : "Ingen funktioner fundet.",//No I18n
	"crm.globalsearch.search.title" : "S\u00f8g",//No I18n
	"None" : "Ingen",//No I18n
	"crm.label.criteria.pattern" : "Kriteriem\u00f8nster",//No I18n
	"crm.label.edit.criteria.pattern" : "Rediger m\u00f8nster",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "M\u00f8nster parenteser matcher ikke.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Ugyldig parentes ved tilstandsoperat\u00f8r(er).",//No I18n
	"crm.criteria.number.notmatch.check" : "Kontroller m\u00f8nster p\u00e5 {0}.",//No I18n
	"criteria.error.alert.other.params" : "Ugyldigt indhold i dette m\u00f8nster.", //No I18n
	"crm.label.search.for.users": "S\u00f8g brugere", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Kriterie-m\u00f8nster matcher ikke de betingelser, du har valgt.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Kriterie-m\u00f8nster matcher ikke de betingelser, du har valgt.", //No I18n
	"and" : "og", //No I18n
	"or" : "eller", //No I18n
	"crm.label.or" : "ELLER", //No I18n
	"crm.label.and" : "OG", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Indtast et gyldigt feltm\u00e6rkat i r\u00e6kken {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Angiv gyldig betingelse for {0}.", //No I18n
	"crm.field.valid.check" : "Indtast venligst et gyldigt {0}.", //No I18n
	"crm.custom.field.less.than.to" : "<i>Fra</i> omr\u00e5de kan ikke v\u00e6re st\u00f8rre end <i>Til</i> omr\u00e5de.", //No I18n
	"crm.alert.label.savepattern" : "Gem m\u00f8nster f\u00f8r du \u00e6ndrer kriterier.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Du kan ikke tilf\u00f8je ekstra kriterier.",//No I18n
	"is" : "er",//No I18n
	"isn\'t" : "er ikke",//No I18n
	"contains" : "indeholder",//No I18n
	"doesn\'t contain" : "indeholder ikke",//No I18n
	"starts with" : "starter med",//No I18n
	"ends with" : "slutter med",//No I18n
	"is empty" : "er tom",//No I18n
	"is not empty" : "er ikke tom",//No I18n
	"is before" : "er f\u00f8r",//No I18n
	"is after" : "er efter",//No I18n
	"between" : "mellem",//No I18n
	"not between" : "ikke mellem",//No I18n
	"Today" : "I dag",//No I18n
	"Tommorow" : "I morgen",//No I18n
	"Tommorow Onwards" : "Starte i morgen",//No I18n
	"Yesterday" : "I g\u00e5r",//No I18n
	"Till Yesterday" : "Indtil i g\u00e5r",//No I18n
	"Last Month" : "Sidste m\u00e5ned",//No I18n
	"Current Month" : "Denne m\u00e5ned", //No I18n
	"Next Month" : "N\u00e6ste m\u00e5ned", //No I18n
	"Last Week" : "Sidste uge", //No I18n
	"Current Week" : "Denne uge", //No I18n
	"Next Week" : "N\u00e6ste uge", //No I18n
	"Age in Days" : "Alder i dage", //No I18n
	"Due in Days" : "Forfalden i dage", //No I18n
	"Scheduled" : "Planlagt", //No I18n
	"Attended Dialled" : "Overv\u00e5get opkald", //No I18n
	"Unattended Dialled" : "Uoverv\u00e5get opkald", //No I18n
	"Overdue" : "Forfaldne", //No I18n
	"Cancelled" : "Annulleret", //No I18n
	"Received" : "Modtaget", //No I18n
	"Missed" : "Ubesvaret", //No I18n
	"crm.alert.character.not.allowed" : "{0} er ikke tilladt", //No I18n
	"crm.condition.in.last" : "i sidste", //No I18n
	"crm.zinvoice.dueIn" : "forfalden i", //No I18n
	"on" : "P\u00e5",//No I18n
	"before" : "inden",//No I18n
	"crm.label.general.small.after" : "efter",//No I18n
	"crm.thisweek" : "Denne uge",//No I18n
	"crm.label.this.month" : "Denne m\u00e5ned",//No I18n
	"crm.thisyear" : "Dette \u00e5r",//No I18n
	"crm.source.user.and.system" : "Bruger og system",//No I18n
	"crm.source.user.or.system" : "Bruger eller system",//No I18n
	"crm.label.system2" : "System",//No I18n
	"crm.source.user.only" : "Kun af bruger",//No I18n
	"crm.source.system.only" : "Kun af system",//No I18n
	"crm.condition.till.today" : "Indtil i dag",//No I18n
	"game.month.left" : "1 m\u00e5ned",//No I18n
	"game.months.left" : "{0} m\u00e5neder",//No I18n
	"crm.condition.last.30.days" : "i de sidste 30 dage",//No I18n
	"crm.condition.last.60.days" : "i de sidste 60 dage",//No I18n
	"crm.condition.last.90.days" : "i de sidste 90 dage",//No I18n
	"crm.label.filter.typehere" : "Skriv her", //No I18N
	"crm.filter.is.not" : "er ikke", //No I18n
	"crm.condition.until.now" : "Indtil nu",//No I18n
	"crm.filter.email.isblocked" : "er blokeret",//No I18n
	"crm.filter.email.isnotblocked" : "er ikke blokeret",//No I18n
	"crm.label.no.results.match" : "Ingen resultat matcher",//No I18n
	"crm.label.select.user" : "Klik for at v\u00e6lge brugere.", //No I18n
	"current.logged.in.user": "Logget bruger", //NO I18n
	"current.logged.in.user.definition": "Brugeren, som p\u00e5begynder Post handlingen.", //NO i18n
	"crm.security.group": "Gruppe", //NO I18n
	"crm.security.role": "Rolle", //NO I18n
	"Date" : "Dato",//No I18n
	"crm.field.valid.decimal.check2" : "Decimaler for feltet <i>{0}</i> skal v\u00e6re mindre end eller lig med {1}.",//No I18n
	"crm.field.empty.check" : "{0} m\u00e5 ikke v\u00e6re tomt.",//No I18n
	"crm.label.add.note": "Tilf\u00f8j note", //NO I18n
	"crm.label.simply.by": "af", //NO I18n
	"crm.general.addnote": "Tilf\u00f8j note", //NO I18n
	"crm.general.addtitle": "Tilf\u00f8j en titel", //NO I18n
	"crm.label.attach.file": "Vedh\u00e6ft fil", //NO I18N
	"crm.button.mass.delete": "Slet", //NO I18N
	"crm.warning.delete.record": "Er du sikker p\u00e5, at du vil flytte \"{0}\" til papirkurven?", //NO I18N
	"crm.label.yes": "Ja", //NO I18N
	"crm.note.view.previous": "Se forrige noter", //NO I18N
  "of": "af", //NO I18N
	"crm.label.notes": "Noter", //NO I18N
	"crm.note.recent.first": "Nyeste f\u00f8rst", //NO I18N
	"crm.note.recent.last": "Nyeste sidst", //NO I18N
	"crm.territory.label.only": "{0} kun", //no i18n
	"crm.select" : "V\u00e6lg",//No I18n
	"crm.button.apply.filter" : "Anvend filter",//No I18n
	"crm.alert.maximum.text.values.contains" : "Du kan ikke indtaste flere end {0} v\u00e6rdier i dette felt.",//No I18n
	"PM" : "F\u00f8r midnat",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "Duplikerede v\u00e6rdier er ikke tilladt.",//no i18n
	"crm.duplicate.value.available": "En eksisterende {0} med samme {1} blev fundet.",//no i18n
	"crm.duplicate.value.available.multiple": "Mere end en {0} med samme {1} blev fundet.",//no i18n
	"crm.custombutton.nobuttons.found": "Ingen knapper er fundet",//no i18n
	"crm.custombutton.create.newbutton": "Opret knap",//no i18n
	"crm.custombutton.manage.button": "Administrer knapper",//no i18n
	"crm.custombutton.name": "Navn",//no i18n
	"crm.customize.custombutton.function.desc": "Beskrivelse",//no i18n
	"crm.custombutton.action": "Knap handling",//no i18n
	"crm.custombutton.empty.field.values": "Tom v\u00e6rdi fundet for f\u00f8lgende felt(er):",//no i18n
	"crm.custombutton.proceed.action": "Er du sikker p\u00e5, at du stadigv\u00e6k vil forts\u00e6tte?",//no i18n
	"zb.common.Cancel": "Annuller",//no i18n
	"crm.yes.proceed": "Ja, forts\u00e6t.",//no i18n
	"crm.label.module.merge": "Flet {0}",//no i18n
	"crm.view.record": "Se {0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "V\u00e6lg fil",//no i18n
	"crm.label.subform.addrows": "Tilf\u00f8j r\u00e6kke",//no i18n
	"crm.button.save&new": "Gem og ny",//no i18n
	//end-cx create form keys
	"Jan" : "Jan",//No I18n
	"Feb" : "Feb",//No I18n
	"Mar" : "Mar",//No I18n
	"Apr" : "Apr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Aug",//No I18n
	"Sep" : "Sep",//No I18n
	"Oct" : "Okt",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dec",//No I18n
	"crm.mb.newversion.msg4":"OK, forst\u00e5et!", //no i18n
	"crm.label.More" :"Mere", //no i18n

	"crm.label.unmapped.stages":"Ikke i konto",//no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Vis mere",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Vis mindre",//No I18n

	//filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"SANDSYNLIGHED",//no i18n
	"crm.lead.prediction.tooltip.score":"SCORE",//no i18n
	"Planned":"Planlagt",//no i18n
	"Invited":"Inviteret",//no i18n
	"Sent":"Sendt",//no i18n
	"Received":"Modtaget",//no i18n
	"Opened":"\u00c5bnet",//no i18n
	"Responded":"Besvaret",//no i18n
	"Bounced":"Afvist",//no i18n
	"Opted\ Out":"Afmeldt",//no i18n
	"crm.filter.label.with.open":"Med \u00e5ben {0}",//no i18n
	"crm.filter.label.without.open":"Uden \u00e5ben {0}",//no i18n
	"crm.filter.label.without.any":"Uden nogen {0}",//no i18n
	"crm.filter.label.with.module":"Med {0}",//no i18n
	"crm.filter.label.activity.due":"{0} Forfalden",//no i18n
	"crm.filter.label.activity.done":"{0} F\u00e6rdig",//no i18n
	"Notes":"Noter",//no i18n
	"crm.filter.label.notes.added":"Noter tilf\u00f8jet",//no i18n
	"crm.label.filter.email.status":"Seneste e-mailstatus",//no i18n
	"crm.label.filter.email.clicked":"klikket",//no i18n
	"crm.label.filter.email.responded":"besvaret", // no i18n
	"crm.label.filter.email.info":"Filtrer poster baseret p\u00e5 den seneste status for dine sendte/modtagne e-mails",//no i18n
	"crm.filter.label.sent":"sendt",//no i18n
	"crm.filter.label.not.sent":"ikke sendt",//no i18n
	"crm.filter.label.opened":"\u00e5bnet",//no i18n
	"crm.filter.label.not.opened":"ikke \u00e5bnet",//no i18n
	"crm.filter.label.received":"modtaget",//no i18n
	"crm.filter.label.not.received":"ikke modtaget",//no i18n
	"crm.filter.label.bounced":"afvist",//no i18n
	"crm.filter.label.opened.not.replied":"\u00e5bnet og ikke besvaret", //no i18n
	"crm.filter.label.any":"En af ovenn\u00e6vnte",//no i18n
	"crm.zia.config.potential.amount":"{0} Bel\u00f8b",//no i18n
	"Quote\ Stage":"{0} Fase",//no i18n
	"crm.module.owner":"{0} Ejer",//no i18n
	"Potential\ Closing\ Date":"{0} Lukkedato",//no i18n
	"crm.lead.prediction.likely.convert":"Sandsynlig konvertering",//no i18n
	"crm.lead.prediction.convert.high":"H\u00f8j",//no i18n
	"crm.lead.prediction.convert.medium":"Middel",//no i18n
	"crm.lead.prediction.convert.low":"Lav",//no i18n
	"crm.predictions.feature.label":"Forudsigelse",//no i18n
	"crm.intelligence.prediction.likelywin":"Sandsynlighed til at vinde",//no i18n
	"crm.intelligence.prediction.likelylose":"Sandsynlighed til at tabe",//no i18n
	"crm.intelligence.prediction.halfchance":"50:50 chance",//no i18n
	"crm.intelligence.prediction.score":"Forudsigelse-scoring",//no i18n
	"crm.lead.prediction.recent.score":"Nylig forudsigelsesscore",//no i18n
	"crm.intelligence.prediction.lastconv":"Seneste 3 samtaler",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Poster, der skal fokuseres p\u00e5",//no i18n
	"crm.intelligence.prediction.slowmoving":"Langsom bev\u00e6gelse",//no i18n
	"crm.intelligence.prediction.trend.down":"Nylig trend ned",//no i18n
	"crm.label.touched.records":"Ber\u00f8rte poster",//no i18n
	"crm.label.untouched.records":"Uber\u00f8rte poster",//no i18n
	"crm.label.record.action":"Post handling",//no i18n
	"workflow.rule.view.label.Modified":"\u00c6ndret",//no i18n
	"crm.label.not.modified":"Ikke \u00e6ndret",//no i18n
	"crm.label.related.records.action":"Relaterede poster handling",//no i18n
	"Done":"Udf\u00f8rt",//no i18n
	"crm.label.not.done":"Ikke udf\u00f8rt",//no i18n
	"sentiment.model":"E-mail synspunkt",//no i18n
	"sentiment.criteria.count":"Antal",//no i18n
	"sentiment.criteria.percentage":"Procentdel",//no i18n
	"sentiment.criteria.lastmail":"For den sidste e-mail",//no i18n
	"Chats":"Chats",//no i18n
	"Attended":"Deltog",//no i18n
	"crm.lead.prediction.popup.text":"Hvis Sandsynlig konvertering er {0}, skal forudsigelsesscoren v\u00e6re mellem {1}.",//no i18n
	"crm.lead.prediction.popup.final":"Rediger filteret tilsvarende, og pr\u00f8v igen.",//no i18n
	"crm.custom.field.less.than.to1":"Fra-omr\u00e5de kan ikke v\u00e6re st\u00f8rre end Til-omr\u00e5de.",//no i18n
	"Last\ Activity\ Date":"Sidste aktivitetsdato",//no i18n
	"crm.label.vendor.name":"{0} Navn",//no i18n
	"hours":"timer",//no i18n
	"days":"dage",//no i18n
	"weeks":"uger",//no i18n
	"months":"m\u00e5neder",//no i18n
	"years":"\u00e5r",//no i18n
	"crm.label.general.small.after":"efter",//no i18n
	"Last\ Week":"Sidste uge",//no i18n
	"Last\ Month":"Sidste m\u00e5ned",//no i18n
	"crm.module.name":"{0} Navn",//no i18n
	"Campaign":"Kampagne",//no i18n
	"Tasks":"Opgaver",//no i18n
	"Calls":"Opkald",//no i18n
	"Events":"Begivenheder",//no i18n
	"sentiment.criteria.wrongcriteria":"Kriteriev\u00e6rdi m\u00e5 ikke v\u00e6re mere end {0}",//no i18n
	"crm.chosen.minimum.input.text":"Indtast {0} tegn eller derover",//no i18n
	"crm.intelligence.prediction.trendup":"Trend op",//no i18n
	"crm.intelligence.prediction.trenddown":"Trend ned",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Udf\u00f8rt" ,//no i18n
	"crm.label.success":"Fuldf\u00f8rt" ,//no i18n
	"crm.label.Failure":"Fejl" ,//no i18n
	"Both":"Begge" ,//no i18n
	"crm.condition.cannot.empty":"Betingelse m\u00e5 ikke v\u00e6re tom.",//no i18n
	"crm.condition.last.30.days":"i de sidste 30 dage",//no i18n
	"crm.condition.last.60.days":"i de sidste 60 dage",//no i18n
	"crm.condition.last.90.days":"i de sidste 90 dage",//no i18n
	"crm.sentiment.Positive":"Positiv",//no i18n
	"crm.sentiment.Negative":"Negativ",//no i18n
	"sentiment.positiveandnegative":"Positiv og negativ",//no i18n
	"sentiment.positiveornegative":"Positiv eller negativ",//no i18n
	"sentiment.positiveonly":"Kun positiv",//no i18n
	"sentiment.negativeonly":"Kun negativ",//no i18n
	"crm.sentiment.Neutral":"Neutral",//no i18n
	"crm.filters.select.campaign.type":"V\u00e6lg {0}-type",//no i18n
	"crm.filters.select.campaign.status":"V\u00e6lg {0}-status",//no i18n
	"campaign.Member" : "Medlem",//no i18n
	"Service":"Tjeneste",//no i18n
	"Activities":"Aktiviteter",//no i18n
	"crm.livedesk.pot.nextdays":"N\u00e6ste {0} dage",//no i18n
	"Today\ +\ Overdue":"I dag + forfaldne",//no i18n
	"crm.source.user.and.system":"Bruger og system",//no i18n
	"crm.source.user.or.system":"Bruger eller system",//no i18n
	"User":"Bruger",//no i18n
	"crm.source.user.only":"Kun af bruger",//no i18n
	"crm.source.system.only":"Kun af system",//no i18n
	"Scheduled":"Planlagt",//no i18n
	"Attended\ Dialled":"Overv\u00e5get opkald",//no i18n
	"Unattended\ Dialled":"Uoverv\u00e5get opkald",//no i18n
	"Cancelled":"Annulleret",//no i18n
	"crm.filter.email.isblocked":"er blokeret",//no i18n
	"crm.filter.email.isnotblocked":"er ikke blokeret",//no i18n
	"condition.till.now":"Indtil nu",//no i18n
	"crm.recurring.no.months":"{0} m\u00e5neder",//no i18n
	"crm.lead.prediction.tooltip":"Sandsynlig konvertering - Scoreomr\u00e5de",//no i18n
	"crm.website.activity":"Websteds aktivitet",//no i18n
	"crm.label.By":"Af",//no i18n
	"crm.chosen.searching.text":"S\u00f8ger...",//no i18n
	"crm.label.memberstatus.is":"og medlemsstatus er",//no i18n
	"crm.events.duration":"Varighed",//no i18n
	"crm.title.clear.name":"Ryd",//no i18n
	"crm.label.status.is":"og status er",//no i18n
	"zia.last3.help":"Samtaler omfatter opkald, opgaver, {0}, e-mail modtaget, noter, bes\u00f8g, sociale kommentarer, supportanmodninger fra Desk.",//no i18n
	"crm.label.tag.related.to":"relateret til",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Ny {0} vil blive oprettet.",//No I18n
	"crm.krp.no.records.found" : "Ingen {0} er fundet",//No I18n
	"crm.module.new" : "Ny {0}",//No I18n
	"crm.label.view" : "Se",//No I18n
	"crm.nsocial.customers" : "Kunder",//No I18n
	"crm.nsocial.open.potential" : "\u00c5ben {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Andre",//No i18n
	"crm.field.length.check" : "{0} v\u00e6rdi overskrider den maksimale l\u00e6ngde.", //No I18n
	"crm.lower.now":"nu",//no i18n
	"crm.time.min.ago":"{0} min. siden",//no i18n
	"crm.time.mins.ago":"{0} min. siden",//no i18n
	"crm.time.hr.ago":"{0} time siden",//no i18n
	"crm.time.hrs.ago":"{0} timer siden", //no i18n
	"AllUsers": "Alle brugere", //no i18n
	"crm.label.search":"S\u00f8g",//no i18n
	"crm.api.filterby":"Filtrer pr.",//no i18n
	"crm.customview.nofields.found":"--Ingen matchende felter--",//no i18n
	"crm.setup.system.ziarecommendation":"Anbefaling",//no i18n
	"crm.filter.label.all.products":"Alle {0}",//no i18n
	"crm.filter.label.select.products":"Udvalgt {0}",//no i18n
	"crm.reviewprocess.smart.filter":"Vurderingsproces poststatus",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"V\u00e6lg en v\u00e6rdi.",//no i18n
	"crm.segmentation.segment.score":"Segment score",//no i18n
	"crm.filter.label.in":"baseret p\u00e5",//no i18n
	"crm.filter.label.and.purchase.in":"og tilb\u00f8jelig til at k\u00f8be i",//no i18n
	"crm.filter.label.last.purchased":"og for nylig erhvervet",//no i18n
	"crm.filter.label.a.day":"en dag",//no i18n
	"crm.filter.label.a.week":"en uge",//no i18n
	"crm.filter.label.a.month":"en m\u00e5ned",//no i18n
	"crm.cal.custom":"Brugerdefineret",//no i18n
	"crm.mb.field.common.empt":"V\u00e6rdien m\u00e5 ikke v\u00e6re tom.",//no i18n
	"crm.chosen.error.loading.text":"Hovsa, vi kunne ikke indl\u00e6se dine resultater",//no i18n
	"crm.filter.label.firstbuy":"F\u00f8rste gang",//no i18n
	"crm.filter.label.cwbab":"Afh\u00e6ngig",//no i18n
	"crm.filter.label.fbt":"Bundt",//no i18n
	"crm.filter.label.rebuy":"Gentag",//no i18n
	"crm.filter.label.nextbuy":"R\u00e6kkef\u00f8lge",//no i18n
	"crm.mxnlookup.select" : "Tildel {0}",//No I18n
	"crm.lookup.chooserecord":"V\u00e6lg {0}",//no i18n
	"crm.record.selected":"Udvalgt {0}",//no i18n
	"crm.module.empty.message" : "Ingen {0} er fundet",//No I18n
	"crm.mxnlookup.selected" : "Tildelt {0}",//No I18n
	"crm.security.error" : "Du har ikke tilstr\u00e6kkelige tilladelser til at udf\u00f8re denne handling. Kontakt administratoren.", //no i18n
	"crm.label.creator.noPermission" : "Tilladelse n\u00e6gtet", //no i18n
	"crm.segmentation.recency" : "Recency",//no i18n
	"crm.segmentation.frequency" : "Hyppighed", //no i18n
	"crm.segmentation.monetary" : "Monet\u00e6r", //no i18n
	"crm.segmentation.segment.score" : "Segment score", //no i18n
	"crm.smartfilter.related.module.msg" : "Du kan ikke v\u00e6lge mere end tre relaterede moduler.", //no i18n
	"crm.smartfilter.related.module.msg1" : "(F.eks.: Email, Activities, Notes)", //no i18n
	"crm.smartfilter.related.module.msg2" : "Varighed kan ikke v\u00e6re tom", //no i18n
	"crm.label.timeZone": "Tidszone", //NO I18n
	"crm.label.insufficient.privileges": "Utilstr\u00e6kkelige rettigheder til at udf\u00f8re denne operation.Kontakt din administrator.", //NO I18n
	"crm.filter.header.secton.system": "Systemdefinerede filtre", //NO I18N
	"crm.filter.header.secton.fields": "Filtrer efter felter", //NO I18N
	"crm.createfield.calcinfo.new" : "Dette felt fungerer som en regnemaskine for alle udtryk, du inds\u00e6tter.</br> <b>F.eks. 20+20</b> vil automatisk give <b>40</b>",//No i18n
	"crm.lable.read.only" : "Skrivebeskyttet felt",//No i18n
	"crm.column.sort.asc" : "Stigende",//No I18n
	"crm.column.sort.desc" : "Faldende",//No i18n
	"crm.column.unsort" : "Fjern sortering",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Del med kunde", //NO I18N
	"crm.label.edited":"Redigeret",//no i18n
	"crm.label.edited.on":"Redigeret den",//no i18n
	"crm.message.limit.exceed": "Kun {0} tegn er tilladt for {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Delt med kunde", //NO I18N
	"crm.button.ok" : "OK",  //NO I18N
	"crm.role.already.selected" :"Denne rolle er allerede valgt", //no i18n
	"crm.user.deleted": "BRUGER ER BLEVET SLETTET",  //NO I18N
	"crm.account.closed": "DENNE KONTO ER BLEVET LUKKET",  //NO I18N
	"crm.start.chat": "Start chat",  //NO I18N
	"crm.start.call": "Start et opkald",  //NO I18N
	"crm.recipient.invalid.email" : "Ugyldige e-mails blev fundet.", //NO I18N
	"crm.recipient.add.recipient" : "Tilf\u00f8j yderligere modtager", //NO I18N
	"crm.start.video.call": "Start et videoopkald",  //NO I18N //ignorei18n_start

	"Score":"Score",
	"Positive Score":"Positiv score",
	"Negative Score":"Negativ score",
	"Touch Point Score":"Ber\u00f8ringspunkt-score",
	"Positive Touch Point Score":"Positiv ber\u00f8ringspunkt-score",
	"Negative Touch Point Score":"Negativ ber\u00f8ringspunkt-score",
	"crm.label.scoring.rules":"Regler for scoring",
	"crm.label.type.minutes": "Indtast her i minutter", //NO I18N
	"is\ OPEN":"er \u00c5BEN",//no i18n
	"is\ WON":"er VUNDET",//no i18n
	"is\ LOST":"er TABT",//no i18n
	"crm.potential.all.open":"Alle \u00e5bne etaper",//no i18n
	"crm.potential.all.won":"Alle lukkede etaper vundet",//no i18n

	"crm.potential.all.lost":"Alle lukkede etaper tabt",//no i18n
	"crm.campaign.member.status" : "Medlemsstatus",//no i18n
	"crm.dashboard.select.type" : "V\u00e6lg {0}",//no i18n
	"crm.campaign.service.status":"Servicestatus",//no i18n

	"crm.label.addColumn":"Tilf\u00f8j kolonne",//no i18n
	"crm.button.clear.filter":"Luk filter",//no i18n
	"crm.button.show.filter":"Vis filter",//no i18n
	"crm.las.error.user.maxlimit":"Du kan v\u00e6lge maks. 20 brugere.",//no i18n
	"crm.las.error.picklist.maxlimit":"Du kan v\u00e6lge maks. 20 valgmuligheder.",//no i18n

	"crm.fileuploader.message.responseerror": "Overf\u00f8rsel mislykkedes", //NO I18N
	"crm.storage.create.error":"Der kan ikke oprettes nogen nye poster, fordi du har n\u00e5et din maksimale datalagergr\u00e6nse.",//no i18n
	"crm.storage.create.error.client":"Der kan ikke oprettes nogen nye poster, fordi din administrator har n\u00e5et sin maksimale lagergr\u00e6nse. Kontakt {0} for at l\u00f8se problemet.",//no i18n
	"crm.storage.avail.info":"({0} resterende af {1})",//no i18n
	"crm.storage.error.key.manage":"Administrer dit datalager",//no i18n
	"Records":"Poster",//no i18n
	"crm.workflow.alert.additional.recipients" : "Ekstra modtagere", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Brug kommaer til at adskille flere e-mail-adresser.", //NO I18N
	"crm.related.contact.account" : "{0} relateret til {1}",//No I18n
	"crm.allcontact.show" : "Alle {0}",//No I18n
	"crm.button.mass.show" : "Vis",//No I18n
	"crm.msg.custom.view.not.replied" : "Ubesvarede meddelelser", //NO I18N
	"crm.msg.custom.view.replied" : "Besvarede meddelelser",//NO I18N
	"crm.workflow.select.recipients" : "Modtagere", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"V\u00e6lg mindst en profil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Beklager, men du kan ikke fjerne en standardprofil.",//NO I18N
	"crm.inv.label.add.emails" : "Tilf\u00f8j e-mails", //NO I18N
	"crm.prediction.analytics.filter.year":"Sidste \u00e5r",//no i18n
	"Previous\ FY":"Forrige FY",//no i18n
	"Current\ FY":"Aktuelle FY",//no i18n
	"Next\ FY":"N\u00e6ste FY",//no i18n
	"Current\ FQ":"Aktuelle FQ",//no i18n
	"Next\ FQ":"N\u00e6ste FQ",//no i18n
	"Previous\ FQ":"Forrige FQ",//no i18n
	"crm.picklist.sample.text":"Eksempeltekst",//no i18n
	"crm.more.colors":"Flere farver",//no i18n
	"crm.button.back.alone":"Tilbage",//no i18n
	"crm.field.label.email":"E-mail",//no i18n
	"crm.zia.nba.feature.label":"N\u00e6ste bedste handling",//no i18n
	"Meeting":"M\u00f8de",//no i18n
	"Tomorrow":"I morgen",//no i18n
	"crm.gdpr.notavailable.field":"Ikke tilg\u00e6ngelig",//no i18n
	"crm.setup.system.ziasimilarity":"Lighedsanbefaling",//no i18n
	"crm.gdpr.notavailable.field":"Ikke tilg\u00e6ngelig",//no i18n
	"crm.label.simply.in":"i",//no i18n
	"crm.filter.label.all.products":"Alle {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "\u201dSegment\u201d", // NO I18N
	"abm.segment.names": "\u201dSegmentnavne\u201d", // NO I18N
	"abm.abm.segment.name": "\u201dABM-segmentnavn\u201d", // NO I18N
	"abm.segmentation.techniques": "\u201dABM-segmenteringstekniker\u201d", // NO I18N
	"abm.filters": "\u201dABM-filtre\u201d", // NO I18N
	"abm.filters.info": "\u201dDisse filtre vil vise information baseret p\u00e5 {0} tilknyttet {1}.\u201d", // NO I18N
	"abm.rfm.label": "\u201dRFM-etiket\u201d", // NO I18N
	"abm.firmographics.label": "\u201dFirmografi-etiket\u201d" ,// NO I18N
	"abm.recommendation.label": "\u201dAnbefalingsetiket\u201d", // NO I18N
	"abm.voc.label": "\u201dVOC-etiket\u201d", // NO I18N
	"abm.engagement.label": "\u201dEngagement-etiket\u201d", // NO I18N
	"abm.overall.score": "\u201dSamlet score\u201d", // NO I18N
	"abm.mood.score": "\u201dStemningsscore\u201d", // NO I18N
	"abm.value.score": "\u201dV\u00e6rdi-score\u201d", // NO I18N
	"abm.engagement.score": "\u201dEngagement-score\u201d", // NO I18N
	"abm.rfm.score": "\u201dRFM-score\u201d", // NO I18N
	"crm.customview.pin.column":"Fastg\u00f8r kolonne",//no i18n
	"crm.customview.unpin.column":"Frig\u00f8r kolonne",//no i18n
	"crm.column.list.max.group.limit" : "Maksimal tilladte gr\u00e6nse er n\u00e5et.", //no i18n
	"crux.custom.field.greater.than.equalto":"{0} skal være større end eller lig med {1}.",
	"crux.users.selected.plural" : "{0} brugere er valgt.",
	"crux.user.selected.singular" :"{0} bruger er valgt.",
	"crux.criteria.empty.secondaryfield.module" : "Ingen matchende {0} feler er fundet i {1}-modul",
	"crux.criteria.empty.secondaryfield" : "Ingen andre {0} felter er tilgængelige til sammenligning, indtast en værdi for at sammenligne.",
	"crux.logged.in.role.definition" : "Brugerrollen, som påbegynder post-handlingen.",
	"zia.similarity.smartfilter.score":"Lighedsscore",//no i18n
	"zia.similarity.smartfilter.records":"Lignende {0} af",//no i18n
	"zia.similarity.smartfilter.records.search":"Vis lignende {0} af",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Du kan ikke v\u00e6lge mere end {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} skal være større end eller lig med {1}.",
	"crux.users.selected.plural" : "{0} brugere er valgt.",
	"crux.user.selected.singular" :"{0} bruger er valgt.",
	"crux.criteria.empty.secondaryfield.module" : "Ingen matchende {0} feler er fundet i {1}-modul",
	"crux.criteria.empty.secondaryfield" : "Ingen andre {0} felter er tilgængelige til sammenligning, indtast en værdi for at sammenligne.",
	"crux.logged.in.role.definition" : "Brugerrollen, som påbegynder post-handlingen.",
	"crux.max.limit.unselect" : "Du kan ikke fravælge flere en {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201e{0}\u201c er allerede valgt", //NO I18N
	"crm.record.locked":"L\u00e5st",//no i18n
	"crm.label.true":"Sandt",//no i18n
	"crm.label.false":"Falsk",//no i18n
	"crm.filter.number.range":"v\u00e6rdier fra {0} til {1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"Du kan ikke v\u00e6lge denne indstilling for mere end 10 valgte v\u00e6rdier.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"Du kan ikke v\u00e6lge mere end 5  {0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Du kan ikke v\u00e6lge mere end {0} {1}",//no i18n
	"crm.best.time.column.label":"Bedste tid til at kontakte",//no i18n
	"crm.competitoralert.mentioned.in.email":"N\u00e6vnt i <b>e-mail</b>",//no i18n
	"crm.competitoralert.with.sentiment":"Med synspunkt",//no i18n
	"crm.competitoralert.name.error":"Angiv en gyldig v\u00e6rdi for konkurrentens navn.",//no i18n
	"crm.competitoralert.date.error":"Angiv venligst en gyldig dato for konkurrentomtale i e-mail.",//no i18n
	"crm.competitoralert.sentiment.error":"V\u00e6lg venligst en gyldig mulighed for konkurrentens stemning.",//no i18n
	"crm.competitor.name":"Konkurrent",
"crm.lookup.advance.error.msg" : "Noget gik galt, mens filteret blev anvendt. Opdater siden, og pr\u00f8v igen.",
	"crm.label.add.tags":"Tilf\u00f8j tags",//no i18n
	"crm.confirm.deassociate":"Fjern tildeling",//no i18n
	"Contact\ Name" : "{0} Navn",
	"Lead\ Name" : "{0} Navn",
	"-None-" : "Ingen",
	"January" : "Januar",
	"February" : "Februar",
	"March" : "Marts",
	"April" : "April",
	"May" : "Maj",
	"June" : "Juni",
	"July" : "Juli",
	"August" : "August",
	"September" : "September",
	"October" : "Oktober",
	"November" : "November",
	"December" : "December",
	// Image Upload literals
	"crm.label.browse.files":"Gennemse filer",//no i18n
	"crm.label.lowercase.or":"eller",//no i18n
	"crm.image.header":"Billede",//no i18n
	"crm.image.n.n":"N:N-forhold",//no i18n
	"crm.image.2.2":"2:2-forhold",//no i18n
	"crm.image.4.3":"4:3-forhold",//no i18n
	"crm.image.16.9":"16:9-forhold",//no i18n
	"crm.image.height.width.swap":"Ombyt h\u00f8jde og bredde",//no i18n
	"crm.image.rotate.image":"Rot\u00e9r",//no i18n
	"crm.label.lowercase.of":"af",//no i18n
	"crm.image.empty.name.placeholder" : "Indtast et navn",
	"crm.image.crop.and.rotate" : "Besk\u00e6r og rot\u00e9r",
	"crm.view.attachment.download" : "Download",
	"crm.label.edit" : "Rediger",
	"crm.label.close" : "Luk",
	"crm.label.any" : "Enhver",
	"crm.image.description" : "Tilf\u00f8j beskrivelse ...",
	"crm.image.zoom.in" : "Zoom ind",
	"crm.image.zoom.out" : "Zoom ud",
	"crm.image.actual.size" : "Aktuel st\u00f8rrelse",
	"crm.image.reset" : "Nulstil",
	"crm.label.lowercase.of" : "af",
	"crm.image.width.px" : "px",
	"crm.image.desc.maxsize" : "Beskrivelsen m\u00e5 ikke overskride 255 tegn.",
	"crm.image.name.maxsize" : "Navnet b\u00f8r ikke overskride 100 tegn.",
	"crm.label.readonly" : "Skrivebeskyttet",
	"crm.fileuploader.message.totalfilesizeexceeded":"Den samlede st\u00f8rrelse overskrider den tilladte gr\u00e6nse p\u00e5 {0}.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"Du kan kun overf\u00f8re op til {0} filer.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"Du kan kun vedh\u00e6fte op til {0} filer.",//no i18n
	"crm.ImageuploadField.size.limit":"Den samlede billedst\u00f8rrelse overskrider den tilladte gr\u00e6nse p\u00e5 {0} MB.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"Du kan kun v\u00e6lger 1 billede",//no i18n
	"crm.image.crop.error":"Kan ikke besk\u00e6re billedet. Pr\u00f8v igen!",//no i18n
	"crm.attach.here":"her.",//no i18n
	"crm.image.unsupported.corrupted.single":"Det foto du pr\u00f8ver at uploade, er \u00f8delagt.",//no i18n
	"Browse":"Gennemg\u00e5",//no i18n
	"crm.imageupload.failure":"Nogle billeder ikke uploades. Pr\u00f8v igen senere.",//no i18n
	"zc.editor.width":"Bredde",//no i18n
	"ze.editor.height":"H\u00f8jde",//no i18n
	"crm.label.delete":"Slet",//no i18n
	"crm.image.error.gif":"GIF-billede kan ikke besk\u00e6res.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} andre filer",//no i18n
	"crm.fileuploader.message.invalidfileType1":"Filen {0} underst\u00f8ttes ikke.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"Filerne {0} og {1} underst\u00f8ttes ikke.",//no i18n
	"crm.attach.upload.sizelimit":"Samlet gr\u00e6nser for st\u00f8rrelse",//no i18n
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "Du kan kun overf\u00f8re op til {0} filer.",
	"crm.file.upload.maxlength.exceeds" : "Du kan kun uploade \u00e9n fil i {0}."

}
